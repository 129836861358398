import React from "react"
import Playground from "../components/Playground"
import resultsJson2024 from "../../results/2024/data/results.json"
import questionsJson2024 from "../../results/2024/data/questions.json"

export default function PlaygroundPage() {
  // console.log('playground', questionsJson2024);
  return (
    <Playground
      data={{ results: resultsJson2024, questions: questionsJson2024 }}
    />
  )
}
