import {Link} from 'gatsby';
import React from 'react';
import Github from '../assets/github.svg';
import Chart from '../assets/Chart.svg';

export const Header = () => {
	return (
		<header id="header"
		        className=" z-[99] relative flex bg-transparent max-w-screen-xl flex-col overflow-hidden px-4 py-4 text-purple-900 md:mx-auto md:flex-row md:items-center">
			<Link to="/">
				<div className="flex cursor-pointer items-center whitespace-nowrap text-2xl font-black">
					<img src="/images/logo.svg" className="h-[64px]"/>
				</div>
			</Link>
			{/*<input type="checkbox" className="peer hidden" id="navbar-open" />*/}
			{/*<label*/}
			{/*  className="absolute top-5 right-7 cursor-pointer md:hidden"*/}
			{/*  htmlFor="navbar-open"*/}
			{/*>*/}
			{/*  <span className="sr-only">Toggle Navigation</span>*/}
			{/*  <svg*/}
			{/*    xmlns="http://www.w3.org/2000/svg"*/}
			{/*    className="h-6 w-6"*/}
			{/*    fill="none"*/}
			{/*    viewBox="0 0 24 24"*/}
			{/*    stroke="currentColor"*/}
			{/*    strokeWidth="2"*/}
			{/*  >*/}
			{/*    <path*/}
			{/*      strokeLinecap="round"*/}
			{/*      strokeLinejoin="round"*/}
			{/*      d="M4 6h16M4 12h16M4 18h16"*/}
			{/*    />*/}
			{/*  </svg>*/}
			{/*</label>*/}
			<nav
				aria-label="Header Navigation"
				className="peer-checked:mt-8 peer-checked:max-h-56 flex max-h-0 w-full flex-col items-center justify-between overflow-hidden transition-all md:ml-24 md:max-h-full md:flex-row md:items-start"
			>
				<ul className="flex flex-col items-center space-y-2 md:ml-auto md:flex-row md:space-y-0">
					<li className="md:mr-6 inline-flex">
						<Link to="/">
							<LinkWIcon label="Home" icon={<HomeIcon/>}/>
						</Link>
					</li>
					<li className="md:mr-6 inline-flex">
						<Link to="/2024">
							<ReportLink year={2024}/>
						</Link>
					</li>
					{/*<li className="md:mr-6">*/}
					{/*  <Link to="/2021">*/}
					{/*    <ReportLink year={2021} />*/}
					{/*  </Link>*/}
					{/*</li>*/}
					{/*<li className="md:mr-6">*/}
					{/*  <Link to="/2022">*/}
					{/*    <ReportLink year={2022} />*/}
					{/*  </Link>*/}
					{/*</li>*/}
					<li className="md:mr-6">
						<Link to="/playground">
							<PlaygroundLink/>
						</Link>
					</li>
					{/*<li className="md:mr-6">*/}
					{/*  <a*/}
					{/*    href="https://github.com/geeksblabla/state-of-dev-uz.com"*/}
					{/*    target="_blank"*/}
					{/*  >*/}
					{/*    <GithubLink />*/}
					{/*  </a>*/}
					{/*</li>*/}
				</ul>
			</nav>
		</header>
	);
};

const ReportLink = ({year}) => (
	<LinkWIcon label={year} icon={<ReportIcon/>}/>
);

const PlaygroundLink = () => (
	<LinkWIcon
		label="Playground"
		icon={<Chart height="24" width="24" className=""/>}
	/>
);

const GithubLink = () => (
	<LinkWIcon label="Github" icon={<Github height="22" width="22"/>}/>
);

const LinkWIcon = ({label, icon}) => (
	<div className="flex cursor-pointer items-center text-purple-900 hover:text-purple-600 pt-2 ">
		<div className="-mr-2 flex-shrink-0 ">
			<div className="h-8 w-8 mt-2">{icon}</div>
		</div>
		<div className="shadow-xs  underline underline-offset-4">{label}</div>
	</div>
);

const ReportIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		aria-hidden="true"
		role="img"
		width="24"
		height="24"
		preserveAspectRatio="xMidYMid meet"
		viewBox="0 0 32 32"
	>
		<path
			fill="currentColor"
			d="m25.7 9.3l-7-7c-.2-.2-.4-.3-.7-.3H8c-1.1 0-2 .9-2 2v24c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V10c0-.3-.1-.5-.3-.7zM18 4.4l5.6 5.6H18V4.4zM24 28H8V4h8v6c0 1.1.9 2 2 2h6v16z"
		/>
		<path fill="currentColor" d="M10 22h12v2H10zm0-6h12v2H10z"/>
	</svg>
);

const HomeIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
	     aria-hidden="true" data-slot="icon" className="h-5 w-5 opacity-75">
		<path stroke-linecap="round" stroke-linejoin="round"
		      d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 1 1 0-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 0 1-1.44-4.282m3.102.069a18.03 18.03 0 0 1-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 0 1 8.835 2.535M10.34 6.66a23.847 23.847 0 0 0 8.835-2.535m0 0A23.74 23.74 0 0 0 18.795 3m.38 1.125a23.91 23.91 0 0 1 1.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 0 0 1.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 0 1 0 3.46"></path>
	</svg>
);
