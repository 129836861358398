// import React from "react"
// import Results from "../components/Results"
// import { graphql } from "gatsby"
// import resultsJson from "../../results/2024/data/results.json"
//
// export const query = graphql`
//   {
//     allMdx(
//       sort: { fields: frontmatter___position, order: ASC }
//       filter: { fileAbsolutePath: { regex: "/2024/" } }
//     ) {
//       edges {
//         node {
//           body
//           id
//           frontmatter {
//             position
//             title
//           }
//         }
//       }
//     }
//   }
// `
//
// const Results2024 = ({ data }) => {
//   console.log('Results2024', data, resultsJson)
//   return <Results data={data} year={2024} results={resultsJson.results} />
// }
//
// export default Results2024;

import React from "react"
import Home from "../components/Home"
import { Layout } from "../components/Layout"

const IndexPage = () => {
  return (
    <Layout>
      <Home />
    </Layout>
  )
}

export default IndexPage
